import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <h3>Privacy Policy</h3>
        <p>
        The data we collect will only be used to create user accounts. Unless otherwise stated, no user details, pictures or content uploaded to the app will be saved our servers. Lotto Kiosk
        </p>
      </header>
    </div>
  );
}

export default App;
